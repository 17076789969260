import { HttpClientInterface } from '@/services/integration/http/httpClientInterface';
import {
  BankAccountInformation,
  CreditCardInformation,
  TokenProviderInterface, TokenResponse
} from '@/services/integration/clients/tokenProviderInterface';
import { injectable } from 'inversify';

type DebitType = 'CREDIT_CARD' | 'BANK_ACCOUNT'

type TokenisationRequest = {
  debitType: DebitType,
  source: string,
  bureauName: string
}

type TokenisationResponse = {
  token: string,
}

type CreditCardTokenisationRequest = TokenisationRequest & CreditCardInformation
type BankAccountTokenisationRequest = TokenisationRequest & BankAccountInformation

type CreditCardTokenisationResponse = TokenisationResponse & { ccExpiryDate: string, pan: string }
type BankAccountTokenisationResponse = TokenisationResponse & { accountNumber: string, bsb: string }

@injectable()
export default class BtbTokenProvider implements TokenProviderInterface {
  private client: HttpClientInterface

  private bureau: string

  private source: string

  constructor (client: HttpClientInterface, bureau: string, source: string) {
    this.client = client;
    this.bureau = bureau;
    this.source = source;
  }

  async getCreditCardToken (id: string, request: CreditCardInformation): Promise<TokenResponse> {
    const creditCardTokenisationRequest: CreditCardTokenisationRequest = {
      ...request,
      debitType: 'CREDIT_CARD',
      source: this.source,
      bureauName: this.bureau
    };

    const response = await this.client.post<CreditCardTokenisationResponse>(`api/v1/carts/${id}/btb/tokenise/cc`, {
      json: creditCardTokenisationRequest
    });

    return response.token;
  }

  async getBankAccountToken (id: string, request: BankAccountInformation): Promise<TokenResponse> {
    const bankAccountTokenisationRequest: BankAccountTokenisationRequest = {
      ...request,
      debitType: 'BANK_ACCOUNT',
      source: this.source,
      bureauName: this.bureau
    };

    const response = await this.client.post<BankAccountTokenisationResponse>(`api/v1/carts/${id}/btb/tokenise/bank`, bankAccountTokenisationRequest);

    return response.token;
  }
}
