import { inject, injectable } from 'inversify';
import { HttpClientInterface } from '../http/httpClientInterface';
import { ShoppingCartClientInterface } from './shoppingCartClientInterface';
import { Cart, CartContactMedium, CartContactMediumCharacteristic, CartItem, CartProduct, Lead } from './types';

@injectable()
export class ShoppingCartClient implements ShoppingCartClientInterface {
  constructor (@inject('ShoppingCartHttpClient') private httpClient: HttpClientInterface) { }

  public async getCart (id: string): Promise<Cart> {
    // ID must exist.
    if (!id.length) {
      throw new Error(`Invalid ID used when fetching shopping cart: ${id}`);
    }

    const url = `api/v1/carts/${id}`;
    const response = await this.httpClient.get<{ data: Cart }>(url);
    return response.data;
  }

  public async createCart (cart: Cart): Promise<Cart> {
    // ID must exist.
    if (cart.id && cart.id.length) {
      throw new Error('Cannot supply ID when creating a new shopping cart.');
    }

    // Recreate the cart object and attempt to fix any validation errors.
    cart = this.rebuildCartData(cart);

    const url = 'api/v1/carts';
    const response = await this.httpClient.post<{ data: Cart }>(url, { json: cart });
    return response.data;
  }

  public async updateCart (cart: Cart): Promise<Cart> {
    // ID must exist.
    if (!cart.id || !cart.id.length) {
      throw new Error('Invalid ID used when updating shopping cart.');
    }

    // Recreate the cart object and attempt to fix any validation errors.
    cart = this.rebuildCartData(cart);

    // Send request.
    const url = `api/v1/carts/${cart.id}`;
    const response = await this.httpClient.put<{ data: Cart }>(url, { json: cart });
    return response.data;
  }

  public async getBPointAuthKey (cartId: string): Promise<string> {
    // ID must exist.
    if (!cartId) {
      throw new Error('Invalid ID used when updating shopping cart.');
    }

    // Send request.
    const url = `api/v2/carts/${cartId}/bpoint/authkey`;
    const response = await this.httpClient.get<{ data: { authkey: string } }>(url);
    return response.data.authkey;
  }

  public async getBPointToken (cartId: string, resultKey: string): Promise<string> {
    // ID must exist.
    if (!cartId) {
      throw new Error('Invalid ID used when updating shopping cart.');
    }

    // Send request.
    const url = `api/v2/carts/${cartId}/bpoint/token/${resultKey}`;
    const response = await this.httpClient.get<{ data: { token: string } }>(url);
    return response.data.token;
  }

  public async updateLead (lead: Lead): Promise<string> {
    const response = await this.httpClient.post<{ crmId: string }>('api/v1/lead', { json: lead });
    return response.crmId;
  }

  /**
   * The update and create endpoints wil fail if incorrect data is
   * passed to them. This method takes a Cart object and attempts to
   * make it valid for the endpoints.
   *
   * @param cart The Cart object as returned by getCart().
   * @returns A valid Cart object.
   */
  private rebuildCartData (cart: Cart): Cart {
    const newCart: Cart = {
      relatedParty: [],
      contactMedium: [],
      cartItem: [],
      cartTotalPrice: []
    };

    // This is importrant if we are updating a record.
    if (cart.id) {
      newCart.id = cart.id;
    }

    // Rebuild cart total price object with valid values.
    newCart.cartTotalPrice = [{
      priceType: 'total',
      price: {
        taxIncludedAmount: {
          amount: cart.cartTotalPrice[0].price.taxIncludedAmount.amount
        }
      }
    }];

    // Rebuild related party object with valid values.
    for (const relatedParty of cart.relatedParty) {
      newCart.relatedParty.push({
        id: relatedParty.id,
        name: relatedParty.name,
        role: relatedParty.role
      });
    }

    // Rebuild contact mediums with valid values.
    for (const contactMedium of cart.contactMedium) {
      // Skip invalid contact mediums.
      const validContactMediums = ['Firstname', 'Lastname', 'Sq', 'Address', 'Email', 'Phone', 'BusinessName', 'Abn', 'CardName', 'CardExpiry', 'CardToken', 'ReferralCode', 'ShippingAddress', 'PowerType', 'BuildingHeight', 'RoofType', 'BuildingMaterials'];
      if (validContactMediums.includes(contactMedium.mediumType)) {
        const newContactMedium: CartContactMedium = {
          id: contactMedium.id,
          mediumType: contactMedium.mediumType,
          mediumCharacteristic: {}
        };

        // Only add characteristics that are not null.
        Object.keys(contactMedium.mediumCharacteristic).forEach(key => {
          const typedKey = key as keyof CartContactMediumCharacteristic;
          if (contactMedium.mediumCharacteristic[typedKey] !== null) {
            newContactMedium.mediumCharacteristic[typedKey] = contactMedium.mediumCharacteristic[typedKey];
          }
        });

        newCart.contactMedium.push(newContactMedium);
      }
    }

    // Rebuild cart items with valid values.
    for (const cartItem of cart.cartItem) {
      const newCartItem: CartItem = {
        cartTerm: [],
        itemPrice: [],
        productOffering: []
      };

      for (const cartTerm of cartItem.cartTerm) {
        newCartItem.cartTerm.push({
          description: cartTerm.description,
          duration: cartTerm.duration,
          name: cartTerm.name
        });
      }

      for (const itemPrice of cartItem.itemPrice) {
        newCartItem.itemPrice.push({
          name: itemPrice.name,
          price: {
            taxIncludedAmount: {
              amount: itemPrice.price.taxIncludedAmount.amount
            }
          }
        });
      }

      // Add product to cart item.
      if (cartItem.product) {
        const newProduct: CartProduct = {
          productSerialNumber: cartItem.product.productSerialNumber,
          characteristic: [],
          productTerm: [],
          name: cartItem.product.name
        };

        for (const characteristic of cartItem.product.characteristic) {
          newProduct.characteristic.push({
            name: characteristic.name,
            value: characteristic.value
          });
        }

        for (const productTerm of cartItem.product.productTerm) {
          newProduct.productTerm.push({
            name: productTerm.name,
            duration: {
              quantity: productTerm.duration.quantity
            }
          });
        }

        newCartItem.product = newProduct;
      }

      newCart.cartItem.push(newCartItem);
    }

    return newCart;
  }
}
